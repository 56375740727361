import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Redirect, Route, Switch } from 'react-router-dom'
import Insights from './Pages/Insights/Insights'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '99%',
  },
}), { name: 'MainRouter' })

export default function MainRouter() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Switch>
        <Route exact path="/">
          <Insights />
        </Route>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </div>
  )
}
