/* eslint-disable import/prefer-default-export */
import { API, appTypes, } from './types'
import store from '../store'

const setSelected = (value) => ({
  type: appTypes.setSelected,
  value,
})

const sendData = (data, setLoading) => ({
  type: API,
  api: {
    method: 'POST',
    url: '/data',
    data: {
      data
    },
    onSuccess: (res) => {
      store.dispatch({
        type: appTypes.setData,
        res,
      })
      setLoading(false)
    },
    onError: () => setLoading(false),
  },
})

export {
  setSelected,
  sendData,
}
