import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Container, useTheme,
} from '@material-ui/core'

import ArrowUp from '../../assets/icons/ArrowUp'
import ArrowDown from '../../assets/icons/ArrowDown'
import LineGraph from '../Generic/LineGraph'
import TransactionsDialog from './TransactionsDialog'

function createData(time, amount) {
  return { time, amount }
}

const randomRange = (min, max) => (Math.random() * (max - min)) + min

const useStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    width: '31%',
    marginTop: spacing(7),
  },
  root: {
    padding: 15,
    boxShadow: '0px 0px 5px 2px rgba(0,0,0,0.15)',
    background: palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 16,
  },
  header: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: 12,
    fontWeight: 'bold',
    paddingRight: 10,
    marginBottom: 2,
    direction: 'rtl',
  },
  subHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  subHeaderContainer: {
    paddingRight: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
  },
  precentContainer: {
    color: ({ increase }) => (increase ? '#1AB870' : '#EF5672'),
    display: 'flex',
    fonSize: 18,
    alignItems: 'center',
    marginRight: 12,
    flexDirection: 'row-reverse',
  },
  chartContainer: {
    height: '16vh',
  },
  headContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
  button: {
    fontWeight: 'bold',
    color: 'white',
    boxShadow: '0 0 0',
    borderRadius: 50
  },
  svgArrow: {
    fill: 'currentColor',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fontSize: '1.5rem',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    flexShrink: '0',
    userSelect: 'none',
  },
  center: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  },
}), { name: 'Card' })

export default function Card(props) {
  const classes = useStyles(props)
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const { palette: { primary, secondary } } = theme

  const {
    header,
    data,
    avg,
    sum,
    precent,
    increase,
    id,
    buttonText = '',
  } = props

  const min = Math.floor(avg * 0.8)
  const max = Math.floor(avg * 1.2)

  return (
    <Container className={classes.container}>
      <div className={classes.root}>
        <div className={classes.headContainer}>
          <div>
            <div className={classes.header}>
              {header}
            </div>
            <div className={classes.subHeaderContainer}>
              <div className={classes.subHeader}>
                {`₪ ${sum}`}
              </div>
              <div className={classes.precentContainer}>
                <span>
                  {`${Math.abs(100 - precent)}%`}
                </span>
                {increase ? (<ArrowUp className={classes.svgArrow} />) : (<ArrowDown className={classes.svgArrow} />)}
              </div>
            </div>
          </div>
          <div className={classes.center}>
            {`₪ ${avg} :הוצאה ממוצעת`}
          </div>
          <div className={classes.center}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => setOpen(true)}
            >
              {buttonText}
            </Button>
          </div>
        </div>
        <div className={classes.chartContainer}>
          <LineGraph
            id={id}
            data={[
              createData('דצמבר', randomRange(min, max)),
              createData('ינואר', randomRange(min, max)),
              createData('פברואר', randomRange(min, max)),
              createData('מרץ', randomRange(min, max)),
              createData('אפריל', sum),
            ]}
            color={increase ? primary : secondary}
            min={min}
            max={max}
          />
        </div>
      </div>
      <TransactionsDialog data={data} open={open} setOpen={setOpen} />
    </Container>
  )
}
