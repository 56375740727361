import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { connect } from 'react-redux'
import UploadFile from './UploadFile'
import uploader from '../../assets/icons/uploader.png'
import { sendData } from '../../store/actions/app'

const useStyles = makeStyles(({ spacing, palette }) => ({
  switchContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: spacing(4),
    marginBottom: spacing(2),
  },
  switch: {
    margin: `0 ${spacing()}px`,
  },
  thumbEn: {
    color: palette.error.light,
  },
  trackEn: {
    background: '#011072',
  },
  thumbHe: {
    border: '1px solid rgba(0, 102, 204, 0.4)',
    color: 'white',
  },
  trackHe: {
    background: '#0066cc',
  },
  overflowVisible: {
    overflowY: 'visible',
  },
  overflowVisible2: {
    overflowY: 'visible',
    display: 'flex',
  },
  dialogContainer: {
    height: 600,
    width: 600,
    display: 'flex',
    flexDirection: 'column',
  },
  dialogActions: {
    padding: 8,
  },
}), { name: 'UploadDialog' })

function UploadDialog(props) {
  const classes = useStyles(props)
  const [data, setData] = useState(null)
  const {
    dispatch, open, setOpen, setOpenLoader
  } = props

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      classes={{ paper: classes.overflowVisible }}
    >
      <div
        style={{
          backgroundImage: `url(${uploader})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
        className={classes.dialogContainer}
      >
        <DialogContent classes={{ root: classes.overflowVisible2 }}>
          <UploadFile handleFiles={setData} />
        </DialogContent>
        <div className={classes.dialogActions}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setOpen(false)
              setOpenLoader(true)
              dispatch(sendData(data, setOpenLoader))
            }}
          >
            טען
          </Button>
          <Button onClick={() => setOpen(false)}>בטל</Button>
        </div>
      </div>
    </Dialog>
  )
}

export default connect()(UploadDialog)
