import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  AppBar, Button, Menu, MenuItem, Toolbar
} from '@material-ui/core'
import { connect } from 'react-redux'
import UploadDialog from '../../Components/UploadDialog/UploadDialog'
import LegoLoading from '../../Components/Loading/LegoLoading'
import Overview from '../../Components/Overview/Overview'
import SideColumn from '../../Components/SideColumn/SideColumn'
import { setSelected } from '../../store/actions/app'

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: '100%',
    marginTop: 64,
    display: 'flex',
    flexDirection: 'row-reverse',
    marginRight: 10,
    marginLeft: 5,
  },
  appButton: {
    color: 'white',
    borderColor: 'white',
  },
  appBar: {
    width: '100%',
    background: `linear-gradient(to right, ${palette.primary.main}, ${palette.primary.main})`,
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  logoContainer: {
    display: 'flex',
    color: 'white',
    fontWeight: 'bold',
    width: '100%',
    justifyContent: 'space-between',
    userSelect: 'none',
    '& > div': {
      letterSpacing: '1.5px',
      fontSize: 20,
    },
  },
}), { name: 'Insights' })

function Insights(props) {
  const classes = useStyles(props)
  const [open, setOpen] = useState(true)
  const [openLoader, setOpenLoader] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const {
    dispatch,
    app,
  } = props

  const handleClick = (event) => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  const handleselect = (select) => {
    dispatch(setSelected(select))
    setAnchorEl(null)
  }

  const handleNew = () => {
    setOpen(true)
    setAnchorEl(null)
  }

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.mainContainer}>
          <div className={classes.logoContainer}>
            <Button
              variant="outlined"
              className={classes.appButton}
              onClick={handleClick}
              aria-controls="simple-menu"
              aria-haspopup="true"
            >
              החלף משתמש
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {Object.keys(app).map((key) => (
                <MenuItem key={key} onClick={() => handleselect(key)}>{key}</MenuItem>
              ))}
              <MenuItem onClick={handleNew}>העלה חדש</MenuItem>
            </Menu>
            <div>תובנות</div>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <SideColumn />
        <Overview />
        <UploadDialog open={open} setOpen={setOpen} setOpenLoader={setOpenLoader} />
        <LegoLoading open={openLoader} />
      </div>
    </>
  )
}

const mapStateToProps = ({ app }) => ({ app })

export default connect(mapStateToProps)(Insights)
