/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

import Card from './Card'
import CardBar from './CardBar'
import InsightsCard from './InsightsCard'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '86%',
  },
}), { name: 'Overview' })

function Overview(props) {
  const classes = useStyles()

  const {
    anomalys,
    spending,
    insights,
    data,
  } = props

  return (
    <div className={classes.container}>
      {/* cards */}
      <CardBar data={spending} />
      {anomalys.map(({
        percentage,
        avg,
        sum,
        description,
        name
      }, i) => (
        <Card
          key={i}
          header={description}
          avg={avg}
          sum={sum}
          increase={percentage > 100}
          precent={percentage}
          buttonText="פרטים"
          id={`color${i + 1}`}
          data={data.filter(({ sub_category, main_category }) => sub_category === name || main_category === name)}
        />
      ))}
      {insights.map(({
        header,
        subHeader,
        boldContent,
        details,
      }) => (
        <InsightsCard
          key={header}
          header={header}
          subHeader={subHeader}
          boldContent={boldContent}
          details={details}
          buttonText="פרטים"
        />
      ))}
    </div>
  )
}

const mapStateToProps = ({ app, selected }) => (app[selected.selected])

export default connect(mapStateToProps)(Overview)
