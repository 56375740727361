import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Container,
} from '@material-ui/core'

import getQueryStringValue from '../../lib/getQueryStringValue'
import header from '../../assets/icons/binleumi-header.png'
import leumi from '../../assets/icons/leumi-header.png'
import mizrahi from '../../assets/icons/mizrahi-header.png'

const headers = {
  leumi,
  mizrahi,
}

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    width: '100%',
    padding: 15,
    marginTop: spacing(2.5),
    display: 'flex',
    flexDirection: 'row-reverse',
    borderRadius: 8,
  },
}), { name: 'HeaderCard' })

export default function HeaderCard(props) {
  const classes = useStyles(props)

  return (
    <Container className={classes.container}>
      <div
        style={{
          backgroundImage: `url(${headers[getQueryStringValue('theme')] || header})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: getQueryStringValue('theme') === 'mizrahi' ? 70 : 46,
          width: 208,
        }}
      />
    </Container>
  )
}
