import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

import SideColumnCard from './SideColumnCard'
import HeaderCard from './HeaderCard'
import SecondCardBar from './SecondCardBar'

const useStyles = makeStyles(({ palette }) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '40%',
    borderLeft: `1px solid ${palette.grey[300]}`,
  },
}), { name: 'SideColumn' })

function SideColumn(props) {
  const classes = useStyles()

  const { credit, debit } = props

  return (
    <div className={classes.container}>
      {/* cards */}
      <HeaderCard />
      <SecondCardBar />
      <SideColumnCard
        header="Available Balance"
        subHeader="ריכוז הכנסות חודשי"
        precent="8%"
        buttonText="Invest"
        id="color2"
        data={credit}
      />
      <SideColumnCard
        header="Loans"
        subHeader="ריכוז הוצאות חודשי"
        precent="70%"
        buttonText="Borrow"
        id="color3"
        data={debit}
        noDivider
      />
    </div>
  )
}

const mapStateToProps = ({ app, selected }) => (app[selected.selected])

export default connect(mapStateToProps)(SideColumn)
