import Immutable from 'seamless-immutable'
import { handleActions } from 'redux-actions'
import { appTypes } from '../actions/types'

const initialState = Immutable({
  5268456: {
    data: [{
      date: '04/25/2021',
      description: 'התקבל מח.בסניף',
      account_id: 128750686,
      user_id: 5268456,
      credit: 10000.0,
      debit: 0.0,
      class: 0,
      steady: 0,
      anomaly_avg: 0.0,
      anomaly_percentage: 0.0,
      anomaly: 0,
      sub_category: 'העברת כספים',
      main_category: 'הכנסות בזכות',
      processed_description: 'התקבל מח בסניף'
    }],
    insights: [
      {
        header: 'הצלחת לחסוך החודש!',
        subHeader: 'נראה שהחודש נשארת עם עודף של עשרת אלפים שקלים',
        boldContent: 'צבור 3% ריבית',
        details: 'בהעברתו לחיסכון מניב',
      },
      {
        header: 'מט״ח',
        subHeader: 'פתח חשבון מט״ח ותוכל להנות',
        boldContent: 'מפטור מעמלות לשנה הראשונה',
        details: 'הוסף עכשיו',
      },
      {
        header: 'משכורת',
        subHeader: 'המשכורת שלך מועברת מידי חודש ללאומי',
        boldContent: 'קבל: פטור מדמי עמלה',
        details: 'למעבירי משכורת מעל 5,000',
      }
    ],
    accounts: [
      {
        balance: 13241,
        number: 3211234,
      },
      {
        balance: 10511,
        number: 331331,
      },
      {
        balance: 30333,
        number: 515151,
      },
      {
        balance: 19302,
        number: 1233321,
      },
    ],
    spending: [
      {
        name: 'דצמבר',
        debit: 1000,
        credit: 2000,
      },
      {
        name: 'ינואר',
        debit: 3000,
        credit: 1112,
      },
      {
        name: 'פברואר',
        debit: 1313,
        credit: 3333,
      },
      {
        name: 'מרץ',
        debit: 1313,
        credit: 2222,
      },
      {
        name: 'אפריל',
        debit: 4444,
        credit: 1002,
      },
    ],
    anomalys: [
      {
        percentage: 300,
        avg: 500,
        description: 'רשות האכיפה והג',
        sum: 1500,
      },
      {
        percentage: 24,
        avg: 505,
        description: 'מסעדות / בתי קפה',
        sum: 121,
      },
      {
        percentage: 6,
        avg: 1034,
        description: 'ציוד ומשרד',
        sum: 62,
      },
    ],
    credit: [
      {
        category: 'משכורת',
        precent: 60,
      },
      {
        category: 'הוראות קבע',
        precent: 25,
      },
      {
        category: 'אחרים',
        precent: 15,
      }
    ],
    debit: [
      {
        category: 'שכר דירה',
        precent: 80,
      },
      {
        category: 'הוראות קבע',
        precent: 15,
      },
      {
        category: 'אחרים',
        precent: 5,
      }
    ],
  },
  5688574: {
    data: [{
      date: '04/25/2021',
      description: 'התקבל מח.בסניף',
      account_id: 128750686,
      user_id: 5268456,
      credit: 10000.0,
      debit: 0.0,
      class: 0,
      steady: 0,
      anomaly_avg: 0.0,
      anomaly_percentage: 0.0,
      anomaly: 0,
      sub_category: 'העברת כספים',
      main_category: 'הכנסות בזכות',
      processed_description: 'התקבל מח בסניף'
    }],
    insights: [
      {
        header: 'משכורת',
        subHeader: 'המשכורת שלך מועברת מידי חודש ללאומי',
        boldContent: 'קבל: פטור מדמי עמלה',
        details: 'למעבירי משכורת מעל 5,000',
      },
      {
        header: 'הכסף שלך שווה יותר',
        subHeader: 'קיים פקדון בלאומי על סכום 21,000',
        boldContent: 'חסוך 3%',
        details: 'בהעברתו לחיסכון מניב',
      },
      {
        header: 'הלוואה',
        subHeader: 'קיימת הלוואה בבנק בינלאומי',
        boldContent: 'בריבית 5%',
        details: 'חסוך וקבל הלוואה בתנאים טובים',
      },
    ],
    accounts: [
      {
        balance: 15151,
        number: 43223,
      },
      {
        balance: 55312,
        number: 1212,
      },
      {
        balance: 141,
        number: 55553,
      },
    ],
    spending: [
      {
        name: 'דצמבר',
        debit: 1512,
        credit: 2222,
      },
      {
        name: 'ינואר',
        debit: 1111,
        credit: 3333,
      },
      {
        name: 'פברואר',
        debit: 4141,
        credit: 5112,
      },
      {
        name: 'מרץ',
        debit: 5555,
        credit: 5555,
      },
      {
        name: 'אפריל',
        debit: 1121,
        credit: 4112,
      },
    ],
    anomalys: [
      {
        percentage: 6,
        avg: 1034,
        description: 'רשות האכיפה והג',
        sum: 62,
      },
      {
        percentage: 6,
        avg: 1034,
        description: 'רשות האכיפה והג',
        sum: 62,
      },
      {
        percentage: 6,
        avg: 1034,
        description: 'רשות האכיפה והג',
        sum: 62,
      },
    ],
    credit: [
      {
        category: 'משכורת',
        precent: 70,
      },
      {
        category: 'הוראות קבע',
        precent: 15,
      },
      {
        category: 'אחרים',
        precent: 15,
      }
    ],
    debit: [
      {
        category: 'שכר דירה',
        precent: 45,
      },
      {
        category: 'הוראות קבע',
        precent: 45,
      },
      {
        category: 'אחרים',
        precent: 10,
      }
    ],
  },
})

export default handleActions({
  [appTypes.setData]: (state, { res }) => Immutable(res),
}, initialState)
