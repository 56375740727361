import React from 'react'
import { Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
// import SecondBarGraph from '../Generic/SecondBarGraph'

const banks = [
  'הבינלאומי',
  'מיזרחי',
  'דיסקונט',
  'פועלים',
]

const useStyles = makeStyles(({ palette }) => ({
  mainContainer: {
    width: '100%',
    padding: 10,
    paddingTop: 20,
  },
  container: {
    width: '100%',
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 8,
  },
  header: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: 12,
    fontWeight: 'bold',
    paddingLeft: 10,
    marginBottom: 2,
  },
  subHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  subHeaderContainer: {
    paddingLeft: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  precentContainer: {
    color: ({ increase }) => (increase ? palette.primary.main : palette.error.main),
    display: 'flex',
    fonSize: 18,
    alignItems: 'center',
    marginLeft: 12,
    flexDirection: 'row',
  },
  chartContainer: {
    height: '30vh',
    width: '17vw',
  },
  headContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  containerStuff: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  mainContent: {
    fontSize: 21,
    color: 'rgba(0, 37, 88, 0.7)',
    letterSpacing: 0.3,
    fontWeight: 'bold',
  },
  secondaryContent: {
    fontSize: 16,
    color: palette.secondary.main,
    letterSpacing: 0.3,
  },
  cont: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginBottom: 20,
  },
  dot: {
    alignSelf: 'center',
    marginLeft: 13,
  },
}), { name: 'CardMain' })

function CardMain(props) {
  const classes = useStyles(props)

  const { accounts } = props

  return (
    <div className={classes.mainContainer}>
      <div className={classes.container}>
        <div className={classes.chartContainer}>
          {/* <SecondBarGraph
            data={accounts.map(({ balance }, i) => ({
              name: banks[i],
              current: balance,
            }))}
          /> */}
          {accounts.map(({ number, balance }, i) => (
            <div key={number} className={classes.cont}>
              <FiberManualRecordIcon className={classes.dot} color="secondary" />
              <div className={classes.containerStuff}>
                <div className={classes.mainContent}>
                  {banks[i]}
                </div>
                <div className={classes.secondaryContent}>
                  {`ח״ן: ${number} יתרה: `}
                  <span style={{ fontWeight: 'bold' }}>{`₪ ${balance}`}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Divider />
    </div>
  )
}

const mapStateToProps = ({ app, selected }) => (app[selected.selected])

export default connect(mapStateToProps)(CardMain)
