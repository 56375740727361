import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import { useCountUp } from 'react-countup'

const useStyles = makeStyles(({ palette, spacing }) => ({
  confidenceTag: {
    marginLeft: spacing(),
    marginRight: spacing(),
    height: ({ size }) => size,
  },
  root: {
    position: 'relative',
  },
  bottom: {
    color: palette.grey[300],
  },
  top: {
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
  label: {
    display: 'flex',
    justifyContent: 'center',
  },
}), { name: 'ConfidenceTag' })

function ConfidenceTag(props) {
  const classes = useStyles(props)
  const {
    value, duration, size, label
  } = props

  const {
    countUp, start,
  } = useCountUp({
    start: 0,
    end: Math.floor(value),
    duration,
    useEasing: false,
  })

  useEffect(() => {
    start()
  }, [value])

  return (
    <div>
      <Box position="relative" display="inline-flex" className={classes.confidenceTag}>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={size}
          thickness={4.5}
          value={100}
        />
        <CircularProgress
          variant="static"
          disableShrink
          className={classes.top}
          classes={{
            circle: classes.circle,
          }}
          size={size}
          thickness={5}
          value={countUp}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h6" component="div" color="textSecondary">
            {countUp}
            %
          </Typography>
        </Box>
      </Box>
      <div className={classes.label}>
        {label}
      </div>
    </div>
  )
}

export default ConfidenceTag
