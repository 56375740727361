import Immutable from 'seamless-immutable'
import { handleActions } from 'redux-actions'
import { appTypes } from '../actions/types'

const initialState = Immutable({
  selected: '5268456'
})

export default handleActions({
  [appTypes.setSelected]: (state, { value }) => state.set('selected', value),
  [appTypes.setData]: (state, { res }) => state.set('selected', Object.keys(res)[0]),
}, initialState)
