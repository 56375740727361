/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { DataGrid } from '@material-ui/data-grid'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    display: 'flex',
    width: '100%',
    height: 580,
  },
  warningIcon: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    color: 'red'
  },
}), { name: 'TransactionsTable' })

const formatHeaderName = (str) => str.replace(/_/g, ' ').toUpperCase()

// eslint-disable-next-line max-len
const fields = ['date', 'description', 'credit', 'debit', 'sub_category', 'main_category', 'anomaly_avg', 'anomaly_percentage', 'anomaly']
const mapedFields = fields.map((field) => ({
  field,
  headerName: formatHeaderName(field),
  width: 140
}))

function TransactionsTable(props) {
  const classes = useStyles(props)
  const { data } = props

  const mapedData = data.map((item, i) => {
    const {
      steady,
      date,
      ...formatedItem
    } = item
    formatedItem.id = i
    formatedItem.date = moment(date).calendar()
    return formatedItem
  })

  return (
    <div className={classes.root}>
      <DataGrid
        rows={mapedData}
        columns={mapedFields}
        autoPageSize
      />
    </div>
  )
}

export default TransactionsTable
