import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import {
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  Area,
  AreaChart,
  ReferenceLine,
} from 'recharts'

// Generate Sales Data

export default function LineGraph(props) {
  const theme = useTheme()
  const {
    data,
    color,
    id,
    min,
    max,
  } = props

  return (
    <ResponsiveContainer>
      <AreaChart
        data={data}
        margin={{
          top: 16,
          right: 16,
          bottom: 0,
          left: 0,
        }}
      >
        <CartesianGrid strokeDasharray="0 3" />
        <XAxis dataKey="time" stroke={theme.palette.text.secondary} />
        <YAxis stroke={theme.palette.text.secondary} />
        <ReferenceLine
          y={min}
          label={{
            position: 'center',
            value: 'תחתון'
          }}
          strokeDasharray="5 5"
        />
        <ReferenceLine
          y={max}
          label={{
            position: 'center',
            value: 'עליון'
          }}
          strokeDasharray="5 5"
        />
        <Tooltip />
        <defs>
          <linearGradient id={id} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={color.main} stopOpacity={1} />
            <stop offset="95%" stopColor={color.light} stopOpacity={1} />
          </linearGradient>
        </defs>
        <Line type="monotone" dataKey="amount" stroke={color.main} dot={false} />
        <Area type="monotone" dataKey="amount" stroke="" fill={`url(#${id})`} fillOpacity={0.6} />
      </AreaChart>
    </ResponsiveContainer>
  )
}
