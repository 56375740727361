import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Container,
  Divider,
} from '@material-ui/core'

import ConfidenceTag from '../Generic/ConfidenceTag'

const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    width: '100%',
    padding: 15,
    marginBottom: spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 8,
  },
  header: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: 12,
    fontWeight: 'bold',
    paddingRight: 10,
    marginBottom: 2,
    direction: 'rtl',
  },
  subHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  subHeaderContainer: {
    paddingRight: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
  },
  precentContainer: {
    color: ({ increase }) => (increase ? palette.primary.main : palette.secondary.main),
    display: 'flex',
    fonSize: 18,
    alignItems: 'center',
    marginRight: 12,
    flexDirection: 'row-reverse',
  },
  chartContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '100%',
  },
  headContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
  button: {
    fontWeight: 'bold',
    color: 'white',
    boxShadow: '0 0 0',
    borderRadius: 50
  },
  svgArrow: {
    fill: 'currentColor',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fontSize: '1.5rem',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    flexShrink: '0',
    userSelect: 'none',
  },
}), { name: 'Card' })

export default function Card(props) {
  const classes = useStyles(props)

  const {
    subHeader,
    data,
    noDivider,
  } = props

  return (
    <Container className={classes.container}>
      <div className={classes.headContainer}>
        <div>
          <div className={classes.subHeaderContainer}>
            <div className={classes.subHeader}>
              {subHeader}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.chartContainer}>
        {data.map(({ category, precent }) => (
          <ConfidenceTag
            key={category}
            value={precent}
            duration={2}
            size={90}
            label={category}
          />
        ))}
      </div>
      {!noDivider && <Divider />}
    </Container>
  )
}
