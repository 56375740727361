import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import { connect } from 'react-redux'
import TransactionsTable from '../Generic/TransactionsTable'

const useStyles = makeStyles(() => ({
  overflowVisible: {
    overflowY: 'visible',
  },
}), { name: 'TransactionsDialog' })

function TransactionsDialog(props) {
  const classes = useStyles(props)
  const {
    open, setOpen, data,
  } = props

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      onClose={() => setOpen(false)}
      classes={{ paper: classes.overflowVisible }}
    >
      <TransactionsTable data={data} />
    </Dialog>
  )
}

export default connect()(TransactionsDialog)
