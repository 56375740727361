/* eslint-disable import/prefer-default-export */
const appTypes = {
  backToState: 'BACK_TO_STATE',
  setData: 'setData',
  setSelected: 'setSelected',
}

const API = 'api'

export {
  appTypes,
  API,
}
