import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import BarGraph from '../Generic/BarGraph'

const useStyles = makeStyles(({ palette }) => ({
  mainContainer: {
    width: '100%',
    padding: 10,
    paddingTop: 20,
  },
  container: {
    width: '100%',
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 8,
  },
  header: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: 12,
    fontWeight: 'bold',
    paddingLeft: 10,
    marginBottom: 2,
  },
  subHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  subHeaderContainer: {
    paddingLeft: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  precentContainer: {
    color: ({ increase }) => (increase ? palette.primary.main : palette.error.main),
    display: 'flex',
    fonSize: 18,
    alignItems: 'center',
    marginLeft: 12,
    flexDirection: 'row',
  },
  chartContainer: {
    height: '30vh',
  },
  headContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}), { name: 'CardMain' })

export default function CardMain(props) {
  const classes = useStyles(props)
  const { data } = props

  return (
    <div className={classes.mainContainer}>
      <div className={classes.container}>
        <div className={classes.chartContainer}>
          <BarGraph data={data} />
        </div>
      </div>
    </div>
  )
}
