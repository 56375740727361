import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Container,
  Divider,
} from '@material-ui/core'

const useStyles = makeStyles(({ spacing, palette, }) => ({
  container: {
    width: '31%',
    marginTop: spacing(7),
  },
  root: {
    padding: 15,
    boxShadow: '0px 0px 5px 2px rgba(0,0,0,0.15)',
    background: palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 16,
  },
  header: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: 12,
    fontWeight: 'bold',
    paddingRight: 10,
    marginBottom: 2,
    direction: 'rtl',
  },
  subHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  subHeaderContainer: {
    paddingRight: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
  },
  precentContainer: {
    color: ({ increase }) => (increase ? '#1AB870' : '#EF5672'),
    display: 'flex',
    fonSize: 18,
    alignItems: 'center',
    marginRight: 12,
    flexDirection: 'row-reverse',
  },
  chartContainer: {
    height: '16vh',
  },
  headContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  button: {
    fontWeight: 'bold',
    color: 'white',
    boxShadow: '0 0 0',
    borderRadius: 50
  },
  svgArrow: {
    fill: 'currentColor',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fontSize: '1.5rem',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    flexShrink: '0',
    userSelect: 'none',
  },
  mainContent: {
    direction: 'rtl',
  },
  mainText: {
    marginTop: 8,
    fontSize: 16,
  },
  mainBold: {
    fontSize: 16,
    marginTop: 8,
    fontWeight: 'bold',
  },
}), { name: 'InsightsCard' })

export default function InsightsCard(props) {
  const classes = useStyles(props)

  const {
    header,
    subHeader,
    boldContent,
    details,
    buttonText = '',
  } = props

  return (
    <Container className={classes.container}>
      <div className={classes.root}>
        <div className={classes.mainContent}>
          <div className={classes.subHeader}>
            {header}
          </div>
          <Divider />
          <div className={classes.mainText}>
            {subHeader}
          </div>
          <div className={classes.mainBold}>
            {boldContent}
          </div>
          <div className={classes.mainText}>
            {details}
          </div>
        </div>
        <div className={classes.headContainer}>
          <div>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => { }}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </Container>
  )
}
