/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Input,
  Button,
} from '@material-ui/core'
import { get } from 'lodash'
import submit from '../../assets/icons/submit.png'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: '1 0 140px',
    margin: ({ margin = `${theme.spacing()}px 0` }) => margin,
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  uploadFile: {
    display: 'none',
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: 13,
    marginBottom: 4,
    lineHeight: '1',
  },
  editButton: {
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& .MuiButton-label': {
      transition: 'all 0.3s',
      '&::before': {
        content: '"⏏"',
        fontSize: '160%',
        left: 28,
        position: 'absolute',
        top: '-330%',
        width: '100%',
      },
    },
    '&:hover': {
      '& .MuiButton-label': {
        transition: 'all 0.3s',
        transform: 'translateY(300%)',
      },

      '&::before': {
        top: 0,
      }
    }
  },
}), { name: 'UploadFile' })

function UploadFile(props) {
  const { className, handleFiles } = props
  const [fileName, setFileName] = useState('לא נבחר קובץ')
  const classes = useStyles(props)

  const onError = () => {
    if (handleFiles) handleFiles(null)
    setFileName('לא נבחר קובץ')
  }

  const onChange = ({ target: { files } }) => {
    const file = files[0]

    const reader = new FileReader()
    reader.onabort = () => onError()

    reader.onerror = () => onError()

    reader.onload = () => {
      const { result } = reader
      try {
        if (handleFiles) handleFiles(window.btoa(result))
        setFileName(get(files[0], 'name', 'לא נבחר קובץ'))
      }
      catch (e) {
        onError()
      }
    }
    try {
      reader.readAsBinaryString(file, 'UTF-8')
    }
    catch (e) {
      onError()
    }
  }

  return (
    <div className={`${classes.container} ${className}`}>
      <div
        style={{
          backgroundImage: `url(${submit})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: 100,
          width: 100,
        }}
        className={classes.dialogContainer}
      />
      <div className={classes.buttonContainer}>
        <Input
          inputProps={{ accept: '.xlsx', disableUnderline: true }}
          disableUnderline
          className={classes.uploadFile}
          id="button-file"
          type="file"
          onChange={onChange}
        />
        <label htmlFor="button-file" className={classes.editButton}>
          <Button style={{ width: 106, color: 'white' }} color="primary" variant="contained" component="span">
            בחר קובץ
          </Button>
        </label>
      </div>
      <span id="file-chosen">{fileName}</span>
    </div>
  )
}

export default UploadFile
